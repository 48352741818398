// biome-ignore lint/suspicious/noExplicitAny: Actual any is required here
export function removeFalseyValuesFromObject<T extends Record<string, any>>(
  obj: T,
): Partial<T> {
  return Object.keys(obj).reduce(
    (acc, key) => {
      const value = obj[key];
      if (value || typeof value === 'boolean' || typeof value === 'number') {
        acc[key as keyof T] = value;
      }
      return acc;
    },
    {} as Partial<T>,
  );
}
