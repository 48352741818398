// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/ban-types */
import { deployEnv, deployRole } from '@vcc-www/constants/config';
import { MarketSite, SiteSlug, getMarketSite } from '@volvo-cars/market-sites';
import sites from './sites.json';

type OptionalMarketSiteProps = 'siteSlug' | 'marketName' | 'regionCode';

/**
 * Extends MarketSite with additional DotCom specific properties.
 */
export type MarketSiteExtended = Omit<MarketSite, OptionalMarketSiteProps> &
  Partial<Pick<MarketSite, OptionalMarketSiteProps>> & {
    /**
     * Default model locale to use for AR in Search Engine for the page associated with one.
     */
    arLocale: string;

    /**
     * Overrides for where the Car Configurator API's differ from the Sitecore Site Slug.
     */
    configuratorSiteSlug?: Exclude<SiteSlug, 'master'>;
  };

export function getExtendedMarketSite(siteSlug?: string) {
  const marketSiteOverrides = sites[siteSlug as keyof typeof sites];
  if (deployEnv === 'prod' && deployRole === 'cd' && siteSlug === 'master') {
    // Don't expose master content in prod
    siteSlug = '';
  }
  const marketSite = getMarketSite(siteSlug);
  const extendedMarketSite: MarketSiteExtended = {
    // Defaults
    configuratorSiteSlug: siteSlug as any,
    arLocale: 'us',
    // Market site data from @volvo-cars/market-sites
    ...marketSite,
    // Data from sites.json
    ...(marketSiteOverrides as {}),
  };
  return extendedMarketSite;
}
