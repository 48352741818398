import { type SiteSlug, isValidSiteSlug } from '@volvo-cars/market-sites';

export const getValidSiteSlug = (siteSlug?: string | string[]): SiteSlug => {
  let siteSlugString = siteSlug;
  if (Array.isArray(siteSlug)) {
    siteSlugString = siteSlug[0];
  }
  const validSiteSlug = isValidSiteSlug(siteSlugString)
    ? siteSlugString
    : 'intl';
  return validSiteSlug;
};
