// TODO: Fix eslint issues the next time this file is edited.
import React, { type ErrorInfo } from 'react';
import { logError } from 'src/utils/logError';
import { ErrorMessages } from '../constants/errorMessages';

export default class ErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: object) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logError(
      `${ErrorMessages.UNCAUGHT_ERROR}:  ${error} ${errorInfo.componentStack ? `with component trace: ${errorInfo.componentStack}` : ''} `,
    );
  }

  render() {
    //probably best to add a fallback UI.
    return this.props.children;
  }
}
