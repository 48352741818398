export function getDeviceMeta() {
  return {
    viewportWidth: window.innerWidth,
    viewportHeight: window.innerHeight,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    pixelRatio: window.devicePixelRatio || 1,
    orientation: getOrientation(),
    preferredColorScheme: window.matchMedia('(prefers-color-scheme: dark)')
      .matches
      ? 'dark'
      : 'light',
    prefersReducedMotion: window.matchMedia('(prefers-reduced-motion: reduce)')
      .matches,
    preferredContrast: window.matchMedia('(prefers-contrast: more)').matches
      ? 'more'
      : window.matchMedia('(prefers-contrast: less)').matches
        ? 'less'
        : window.matchMedia('(prefers-contrast: custom)').matches
          ? 'custom'
          : 'no-preference',
    prefersReducedTransparency: window.matchMedia(
      '(prefers-reduced-transparency: reduce)',
    ).matches,
    colorGamutSupport: window.matchMedia('(color-gamut: p3)').matches
      ? 'p3'
      : 'srgb',
  };
}

function getOrientation(): OrientationType | 'portrait' | 'landscape' {
  if (window.screen.orientation) {
    return window.screen.orientation.type;
  }
  return window.matchMedia('(orientation: landscape)').matches
    ? 'landscape'
    : 'portrait';
}
