import { addTrailingSlash } from '@vcc-www/utils/toggleSlashes.mjs';
import { SiteSlug } from '@volvo-cars/market-sites';

const runtimeConfig: { [key: string]: string } | undefined =
  typeof window !== 'undefined' && (window as any).VolvoCarsDotComConfig;

export const sitecoreGraphQLEndpoint =
  runtimeConfig?.sitecoreGraphQLEndpoint ||
  process.env.VCC_WWW_SITECORE_GRAPHQL_ENDPOINT;
export const publicPath =
  runtimeConfig?.publicPath ||
  addTrailingSlash(process.env.VCC_WWW_JSS_RENDERING_ENGINE_URL || '') ||
  addTrailingSlash(process.env.VCC_WWW_PUBLIC_PATH || '') ||
  '/';
export const sitecoreLayoutServiceEndpoint =
  runtimeConfig?.sitecoreLayoutServiceEndpoint ||
  process.env.VCC_WWW_SITECORE_LAYOUT_SERVICE_ENDPOINT;
export const dotComSentryDsn =
  runtimeConfig?.dotComSentryDsn || process.env.VCC_WWW_DOTCOM_SENTRY_DSN;
export const deployEnv = process.env.VCC_WWW_DEPLOY_ENV as
  | 'dev'
  | 'test'
  | 'qa'
  | 'prod';

export const deployRole = process.env.VCC_WWW_DEPLOY_ROLE || '';

export const assetPrefix = process.env.VCC_WWW_ASSET_PREFIX || '';

export const defaultLanguage = process.env.VCC_WWW_DEFAULT_LANGUAGE || 'en';

export const nodeEnv = process.env.NODE_ENV;

export const sitecoreAppName = process.env.VCC_WWW_SITECORE_APP_NAME;

export const defaultSiteSlug = process.env
  .VCC_WWW_DEFAULT_SITE_SLUG as SiteSlug;

export const sitecoreGraphQLHost =
  process.env.VCC_WWW_SITECORE_GRAPHQL_HOST || '';

export const sitecoreLayoutServiceHost =
  process.env.VCC_WWW_SITECORE_LAYOUT_SERVICE_HOST || '';

export const buildAndPriceGraphQLEndpoint =
  runtimeConfig?.buildAndPriceGraphQLEndpoint ||
  process.env.VCC_WWW_BUILD_AND_PRICE_GRAPQHQL_URI ||
  '/';

export const isStorybookVisualRegression =
  !!process.env.STORYBOOK_VISUAL_REGRESSION &&
  process.env.STORYBOOK_VISUAL_REGRESSION !== 'false';

export const isStorybook =
  !!process.env.STORYBOOK_ENV && process.env.STORYBOOK_ENV !== 'false';

export const smartlingOrgId = process.env.VCC_WWW_SMARTLING_ORGID;
export const siteNavigationBaseUrl = process.env.VCC_WWW_SITE_NAV_URL;
export const siteFooterBaseUrl = process.env.VCC_WWW_SITE_FOOTER_URL;

export const launchDarklyClientSideId =
  runtimeConfig?.launchDarklyClientSideId ||
  process.env.VCC_WWW_LAUNCHDARKLY_CLIENT_SIDE_ID;

export const launchDarklyClientSideGlobalId =
  runtimeConfig?.launchDarklyClientSideGlobalId ||
  process.env.VCC_WWW_LAUNCHDARKLY_CLIENT_SIDE_GLOBAL_ID;

export const geoCodeUrl =
  runtimeConfig?.geoCodeUrl || process.env.VCC_WWW_GEOCODE_URL || '';
export const googleApiKey =
  runtimeConfig?.googleApiKey || process.env.VCC_WWW_GOOGLE_API_KEY || '';

export const chargingStationGoogleApiKey =
  runtimeConfig?.chargingStationGoogleApiKey ||
  process.env.VCC_WWW_CHARGING_STATION_GOOGLE_API_KEY ||
  '';
