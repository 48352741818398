import React from 'react';
import {
  StyleRenderer as IStyleRenderer,
  StyleProvider,
  StyleRendererConfig,
  ThemePicker,
  getTheme,
  styleRenderer,
} from 'vcc-ui';

const lightTheme = getTheme({ variant: 'light' });

export type StyleRenderer = IStyleRenderer;

export function createRenderer(
  config?: StyleRendererConfig,
  rootId: string = '#root',
) {
  const renderer = styleRenderer(config);

  // Fix for font scaling on iOS
  renderer.renderStatic(
    {
      ['-webkit-text-size-adjust' as string]: '100%',
    },
    'html',
  );

  renderer.renderStatic(
    {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      margin: 0,
      height: '100%',
      fontFamily: lightTheme.fontTypes.NOVUM,
      ['-webkit-font-smoothing' as string]: 'antialiased',
      backgroundColor: '#fff',
    },
    'body',
  );

  // Ensures footer gets pushed to the bottom even if the content doesn't
  // cover the viewport height.
  renderer.renderStatic(
    {
      flex: 1,
    },
    rootId,
  );

  renderer.renderStatic(
    {
      boxSizing: 'border-box',
    },
    '*',
  );

  renderer.renderStatic(
    {
      ['-webkit-font-smoothing' as string]: 'auto',
    },
    'a, button',
  );

  return renderer;
}

type Props = {
  children: React.ReactNode;
  renderer?: StyleRenderer;
  direction?: 'ltr' | 'rtl';
  targetDocument?: Document;
  lang?: string;
};

const GlobalStyleProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  renderer = createRenderer(),
  direction = 'ltr',
  targetDocument,
  lang,
}) => {
  return (
    <StyleProvider targetDocument={targetDocument} renderer={renderer}>
      <ThemePicker variant="light" direction={direction}>
        <div dir={direction} {...(lang && { lang })}>
          {children}
        </div>
      </ThemePicker>
    </StyleProvider>
  );
};

export default GlobalStyleProvider;
