'use client';
import React, { createContext, useState, useEffect } from 'react';
import { VolvoCarsUrl, urlFromString } from './VolvoCarsUrl';

const defaultPathname =
  typeof window === 'undefined' ? '/' : window.location.pathname;

export const VolvoCarsUrlContext = createContext<VolvoCarsUrl>(
  urlFromString(defaultPathname),
);

export type VolvoCarsUrlProviderProps = {
  url: VolvoCarsUrl;
  /**
   * Allow modifying the URL before it gets updated.
   */
  beforeUrlUpdate?(newUrl: VolvoCarsUrl): VolvoCarsUrl;
  /**
   * A setState function to forward route change handler to be used in custom route implementations
   */
  setRouteChangeHandler?: React.Dispatch<
    React.SetStateAction<(() => void) | undefined>
  >;
};

export const VolvoCarsUrlProvider: React.FC<
  React.PropsWithChildren<VolvoCarsUrlProviderProps>
> = ({ children, url: initialUrl, beforeUrlUpdate, setRouteChangeHandler }) => {
  const [url, setUrl] = useState(initialUrl);
  useEffect(() => {
    let allowStateUpdate = true;
    const handleChange = () => {
      if (allowStateUpdate) {
        setUrl((currentUrl) => {
          if (currentUrl.href === window.location.href) {
            // Returning the same URL instance prevents re-renders
            return currentUrl;
          }
          const newUrl = urlFromString(window.location.href);
          if (typeof beforeUrlUpdate === 'function') {
            return beforeUrlUpdate(newUrl);
          }
          return newUrl;
        });
      }
    };
    // optionally forward change handler to consumers for custom route change events
    setRouteChangeHandler?.(() => handleChange);

    // click events might trigger a history change, so we potentially update
    // the URL after all clicks.
    // We could monkey patch `history.pushState` and `history.replaceState`
    // but it can be a bit risky in case some other script does the same.
    const globalClickListener = () => requestAnimationFrame(handleChange);
    document.body.addEventListener('click', globalClickListener);

    window.addEventListener('popstate', handleChange);
    return () => {
      allowStateUpdate = false;
      document.body.removeEventListener('click', globalClickListener);
      window.removeEventListener('popstate', handleChange);
    };
  }, [beforeUrlUpdate, setRouteChangeHandler]);
  return (
    <VolvoCarsUrlContext.Provider value={url}>
      {children}
    </VolvoCarsUrlContext.Provider>
  );
};
