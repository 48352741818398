import type { TrackingData } from '../types';

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function keysToSnakeCase(
  trackingValue?: TrackingData | TrackingData[string],
): TrackingData | TrackingData[string] {
  if (typeof trackingValue !== 'object' || trackingValue === null) {
    return trackingValue;
  }
  const result: TrackingData = {};
  if (Array.isArray(trackingValue)) {
    return trackingValue.map((values) => keysToSnakeCase(values));
  }
  for (const [key, value] of Object.entries(trackingValue)) {
    result[camelToSnakeCase(key)] = keysToSnakeCase(value);
  }
  return result;
}
