/**
 * Returns a randomly generated UUID
 * @remarks
 * Has been implemented with a fallback generation as IOS 15.3 and below do not support crypto.randomUUID
 * https://caniuse.com/?search=crypto.randomUUID
 */

export const generateUUID = (): string => {
  if (crypto.randomUUID !== undefined) {
    return crypto.randomUUID();
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c: string) => {
      const r =
        (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) |
        (c === 'x' ? 0 : 8);
      return r.toString(16);
    },
  );
};
