export function isPageTypeOrPageNameEvent(entry: unknown) {
  return (
    typeof entry === 'object' &&
    entry !== null &&
    ('pageType' in entry ||
      'pageName' in entry ||
      'page_type' in entry ||
      'page_name' in entry)
  );
}
