import { getPageViewCache } from './PageViewCache';
import type {
  TrackEvent,
  TrackEventLegacy,
  TrackerOptions,
} from './TrackerOptions';
import { extractCampaignParams } from './campaign/extractCampaignParams';
import { getActiveGroups, onConsentChangedOnce } from './consent/consent';
import { getDeviceMeta } from './device/getDeviceMeta';
// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable no-restricted-properties */
import {
  type EventBatchTransporter,
  getEventBatchTransporter,
} from './event-batch-transporter/EventBatchTransporter';
import { getTrackingServiceEnvironment } from './event-batch-transporter/shouldEnableTrackingService';
import {
  type TrackingSession,
  getTrackingSession,
} from './tracking-session/TrackingSession';
import type {
  AccountCreatedData,
  AddPaymentInfoData,
  AddToCartData,
  BeginCheckoutData,
  CartViewData,
  ChatInteractionData,
  ConfigFinishData,
  ConfigStartData,
  CustomEventData,
  EventStrict,
  FormLoadData,
  FormSubmitData,
  GenericValue,
  LoginData,
  PageViewData,
  PurchaseData,
  SiteNavInteractionData,
  TrackingData,
  ViewItemData,
} from './types';
import { attachGetDimension } from './utils/attachGetDimension';
import { handleSessionReplayInitOnce } from './utils/handleSessionReplayInitOnce';
import { isPageTypeOrPageNameEvent } from './utils/isPageTypeOrPageNameEvent';
import { camelToSnakeCase, keysToSnakeCase } from './utils/keysToSnakeCase';
import { removeFalseyValuesFromObject } from './utils/removeFalseyValuesFromObject';

attachGetDimension();

/**
 * Exposes methods to push analytics data for different events, with some
 * default data given when creating the tracker.
 */
export class Tracker {
  private readonly defaultEventData?: TrackingData | null;
  private readonly forceLowerCase: boolean;
  private readonly deferNonInteraction: boolean;
  private readonly logging: boolean;
  public disabled?: boolean;
  private readonly trackingSession?: TrackingSession;
  private readonly eventBatchTransporter?: EventBatchTransporter;
  private readonly trackingServiceEnvironment?: 'prod' | 'non-prod';

  constructor(
    eventData?: TrackingData | null,
    {
      forceLowerCase = true,
      logging = false,
      disabled = false,
      deferNonInteraction = true,
      trackingServiceConfig = { status: 'enabled' },
    }: TrackerOptions = {},
  ) {
    this.defaultEventData = eventData;
    this.forceLowerCase = forceLowerCase;
    this.deferNonInteraction = deferNonInteraction;
    this.logging = logging;
    this.disabled = disabled;

    // binding these to the instance to ensure that
    // `new Tracker({ eventCategory: 'Foo' }).interaction !== new Tracker({ eventCategory: 'Bar' }).interaction`
    this.interaction = this.interaction.bind(this);
    this.nonInteraction = this.nonInteraction.bind(this);
    this.pushCustomDimension = this.pushCustomDimension.bind(this);
    this.virtualPageView = this.virtualPageView.bind(this);
    this.trackingSession = getTrackingSession();
    this.eventBatchTransporter = getEventBatchTransporter();
    this.trackingServiceEnvironment = getTrackingServiceEnvironment(
      trackingServiceConfig,
    );
  }

  // To be replaced by sendEvent when @deprecated sendEvent method is removed
  private _sendEvent(
    event?: TrackEvent | TrackEventLegacy,
    eventData?: TrackingData,
  ) {
    const analytics: TrackingData = {
      ...(event === 'custom_event' && {
        eventCategory: 'not set',
        eventAction: 'not set',
        eventLabel: 'not set',
      }),
      ...this.defaultEventData,
      ...eventData,
      event: this.getEvent(event),
    };

    // Disable in tests for now as it'll require a major change in
    // all tests and releasing a major tracking version
    if (!this.trackingSession || !this.trackingServiceEnvironment) {
      this.push(analytics, { snakeCaseKeys: true });
    } else {
      this.trackingSession?.generateConfig().then((config) => {
        const data = this.push(analytics, { snakeCaseKeys: true });

        if (!data) {
          return;
        }

        const sessionReplayProperties =
          window.sessionReplay?.getSessionReplayProperties?.() || {};

        if (!window.sessionReplay?.getSessionReplayProperties) {
          handleSessionReplayInitOnce(this.eventBatchTransporter);
        }

        const pageViewCache = getPageViewCache();
        const lastPageViewData = pageViewCache?.get();

        const eventWithMeta: Record<string, unknown> = {
          ...(data.event !== 'page_view' &&
            lastPageViewData &&
            removeFalseyValuesFromObject({
              page_name: lastPageViewData.pageName,
              page_type: lastPageViewData.pageType,
              url: lastPageViewData.url,
              previous_page_name: lastPageViewData.previousPageName,
              sub_page_name: lastPageViewData.subPageName,
            })),
          ...data,
          session_id: config.sessionId,
          device_id: config.deviceId,
          event_id: crypto.randomUUID(),
          event_timestamp: new Date().toISOString(),
          user_agent: window.navigator.userAgent,
          ...sessionReplayProperties,
        };

        this.eventBatchTransporter?.queueEvent(eventWithMeta);
      });
    }
  }

  private push(
    eventData?: TrackingData,
    { snakeCaseKeys = false, lowerCaseValues = this.forceLowerCase } = {},
  ) {
    if (typeof window === 'undefined') {
      return;
    }

    if (!eventData || this.disabled) {
      return;
    }

    if (!('dataLayer' in window)) {
      // @ts-expect-error dataLayer is a global variable
      window.dataLayer = [];
    }

    const transformedEventData = this.transformEventData(eventData, {
      snakeCaseKeys,
      lowerCaseValues,
    });

    if (process.env.NODE_ENV === 'development' && this.logging) {
      console.debug('[Analytics]', transformedEventData);
    }

    // GTM adds gtm.uniqueEventId to each event in the dataLayer
    // This creates a new object reference so this id doesn't get sent
    // to the tracking service
    window.dataLayer.push({ ...transformedEventData });

    return transformedEventData;
  }

  private transformEventData(
    eventData: TrackingData,
    { snakeCaseKeys = false, lowerCaseValues = this.forceLowerCase } = {},
  ) {
    const transformedEventData: TrackingData = {};

    for (let [key, value] of Object.entries(eventData)) {
      if (value === null || value === '') {
        continue;
      }

      if (typeof value === 'string' && lowerCaseValues) {
        value = value.toLowerCase();
      }

      if (snakeCaseKeys) {
        transformedEventData[camelToSnakeCase(key)] = keysToSnakeCase(value);
      } else {
        transformedEventData[key] = value;
      }
    }
    return transformedEventData;
  }

  // Wait for up to `retries` seconds for a pageType or pageName event in the
  // dataLayer before pushing the event
  private deferedNonInteraction(
    event: TrackEvent | TrackEventLegacy,
    eventData?: TrackingData,
    retries = 60,
  ) {
    const hasPageTypeEvent =
      Array.isArray(window.dataLayer) &&
      window.dataLayer.some(isPageTypeOrPageNameEvent);
    if (hasPageTypeEvent || retries <= 0) {
      this._sendEvent(event, eventData);
    } else {
      window.setTimeout(() => {
        this.deferedNonInteraction(event, eventData, retries - 1);
      }, 1000);
    }
  }

  /**
   * @deprecated Use customEvent instead.
   */
  nonInteraction(eventData?: TrackingData) {
    if (this.deferNonInteraction) {
      this.deferedNonInteraction(
        this.defaultEventData?.event || 'custom_event',
        eventData,
      );
    } else {
      this._sendEvent(
        this.defaultEventData?.event || 'custom_event',
        eventData,
      );
    }
  }

  /**
   * @deprecated Use customEvent instead.
   */
  interaction(eventData?: TrackingData) {
    this._sendEvent(this.defaultEventData?.event || 'custom_event', eventData);
  }

  /**
   * @deprecated Use customEvent instead.
   */
  sendEvent(event?: TrackEvent, eventData?: TrackingData) {
    this._sendEvent(event, eventData);
  }

  private getEvent(event?: TrackEvent | TrackEventLegacy) {
    if (event) {
      return event;
    }
    if (this.defaultEventData?.event) {
      return this.defaultEventData.event;
    }
    return 'custom_event';
  }

  customEvent(customEventData: CustomEventData & EventStrict) {
    this._sendEvent('custom_event', customEventData);
  }

  siteNavInteraction(siteNavInteractionData: SiteNavInteractionData) {
    this._sendEvent('sitenav_interaction', siteNavInteractionData);
  }

  chatInteraction(chatInteractionData: ChatInteractionData) {
    this._sendEvent('chat_interaction', chatInteractionData);
  }

  private setPreviousPageData(
    pageName: GenericValue | undefined,
    url: GenericValue | undefined,
  ) {
    if (typeof pageName === 'string') {
      this.trackingSession?.setItem('previousPage', {
        name: pageName,
        url: (url as string) || window.location.href,
      });
    }
  }

  pageView(pageViewData: PageViewData) {
    const hasPageViewData = Object.keys(pageViewData).length;

    if (!hasPageViewData) {
      return;
    }

    const campaignParams = extractCampaignParams();

    const data = {
      ...campaignParams,
      ...pageViewData,
      userAgent: window.navigator.userAgent,
      referring_domain: window.document.referrer,
    };

    const pageViewCache = getPageViewCache();

    if (
      this.trackingSession &&
      'getItem' in this.trackingSession &&
      this.trackingServiceEnvironment
    ) {
      this.trackingSession.getItem('previousPage').then((previousPage) => {
        const extendedData = {
          ...data,
          url: data.url || window.location.href,
          previousPageName: previousPage?.name ?? 'landing page',
          previousPageUrl: previousPage?.url,
          onetrustActiveGroups: getActiveGroups(),
          deviceMeta: getDeviceMeta(),
        };

        pageViewCache?.set(extendedData);

        onConsentChangedOnce((groups) => {
          this.eventBatchTransporter?.patchQueuedEvents(
            {
              consent_groups: groups,
            },
            ['page_view'],
          );
          this.setPreviousPageData(data.pageName, data.url);
        });

        this._sendEvent('page_view', extendedData);

        this.setPreviousPageData(data.pageName, data.url);
      });
    } else {
      this._sendEvent('page_view', data);
    }
  }

  viewItem(viewItemData: ViewItemData) {
    this._sendEvent('view_item', viewItemData);
  }

  addToCart(addtoCartData: AddToCartData) {
    this._sendEvent('add_to_cart', addtoCartData);
  }

  cartView(cartViewData: CartViewData) {
    this._sendEvent('view_cart', cartViewData);
  }

  beginCheckout(beginCheckoutData: BeginCheckoutData) {
    this._sendEvent('begin_checkout', beginCheckoutData);
  }

  addPaymentInfo(addPaymentInfoData: AddPaymentInfoData) {
    this._sendEvent('add_payment_info', addPaymentInfoData);
  }

  purchase(purchaseData: PurchaseData) {
    this._sendEvent('purchase', purchaseData);
  }

  configStart(configStartData: ConfigStartData) {
    this._sendEvent('config_start', {
      ...configStartData,
      configurationStart: 1,
    });
  }

  configFinish(configFinishData: ConfigFinishData) {
    this._sendEvent('config_finish', {
      ...configFinishData,
      configurationFinish: 1,
    });
  }

  formLoad(formLoadData: FormLoadData) {
    this._sendEvent('form_load', { ...formLoadData, formLoads: 1 });
  }

  formSubmit(formSubmitData: FormSubmitData) {
    this._sendEvent('form_submit', {
      ...formSubmitData,
      formSubmission: 1,
    });
  }

  virtualPageView(eventData?: TrackingData) {
    this._sendEvent('page_view', eventData);
  }

  pushCustomDimension(name: string, value?: string | boolean | number) {
    this.push(
      { [name]: value },
      { snakeCaseKeys: true, lowerCaseValues: false },
    );
  }

  search(searchTerm: string) {
    this._sendEvent('search', {
      searchTerm: searchTerm,
    });
  }

  globalSearchSubmit(searchTerm: string) {
    this._sendEvent('global_search_submit', {
      searchTerm: searchTerm,
    });
  }

  login(eventData?: LoginData) {
    this._sendEvent('login', eventData);
  }

  accountCreated(eventData?: AccountCreatedData) {
    this._sendEvent('account_created', eventData);
  }

  testDriveBookingCancelled(eventData: TrackingData) {
    this._sendEvent('test_drive_booking_cancelled', eventData);
  }
}
