import { hasTrackingConsent } from '../consent/consent';

const retryableStatusCodes = [
  408, // Request Timeout
  429, // Too Many Requests
  500, // Internal Server Error
  502, // Bad Gateway
  503, // Service Unavailable
  504, // Gateway Timeout
];

type Environment = 'prod' | 'non-prod';

export class EventBatchTransporter {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  private queue: Record<string, any>[] = [];
  private maxBatchSize: number;
  private flushInterval: number;
  private retryAttempts: number;
  private retryDelay: number;
  private flushTimer: NodeJS.Timeout | null = null;
  private endpoint: string | undefined;
  private failedSendAttempts: number;

  constructor({ environment }: { environment?: Environment } = {}) {
    this.maxBatchSize = 10;
    this.flushInterval = 10000;
    this.retryAttempts = 3;
    this.retryDelay = 1000;
    this.endpoint = this.getEndpoint(environment);
    this.failedSendAttempts = 0;

    if (typeof window !== 'undefined') {
      // Send batches on visibility change
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState === 'hidden') {
          this.sendBatches({ useBeacon: true });
        }
      });
    }
  }

  private startFlushTimer() {
    if (!this.flushTimer) {
      this.flushTimer = setInterval(() => this.flush(), this.flushInterval);
    }
  }

  private stopFlushTimer() {
    if (this.flushTimer) {
      clearInterval(this.flushTimer);
      this.flushTimer = null;
    }
  }

  public patchQueuedEvents(
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    properties: Record<string, any>,
    eventNames?: string[],
  ) {
    for (const event of this.queue) {
      if (eventNames && !eventNames.includes(event.name)) {
        continue;
      }

      Object.assign(event, properties);
    }
  }

  /**
   * Adds an event to the queue and starts the flush timer if not already started.
   * If the queue length reaches the maximum batch size, it flushes the queue.
   *
   * @param eventData - The event data to be queued.
   */

  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  queueEvent(eventData: Record<string, any>) {
    if (!this.endpoint) {
      return;
    }

    this.queue.push(eventData);
    this.startFlushTimer();

    if (this.queue.length >= this.maxBatchSize) {
      this.flush();
    }
  }

  private getEndpoint(environment?: Environment) {
    if (typeof window === 'undefined') {
      return;
    }

    const url = new URL(window.location.href);

    if (url.hostname === 'localhost') {
      return;
    }

    const siteSlug = url.pathname.split('/')[1]?.toLowerCase();

    const isChina = siteSlug === 'zh-cn';

    if (isChina) {
      return;
    }

    if (environment === 'prod') {
      return 'https://www.volvocars.com/api/t/v1';
    }

    if (environment === 'non-prod') {
      return 'https://qawww.volvocars.com/api/t/v1';
    }

    return `${url.origin}/api/t/eu/v1`;
  }

  // biome-ignore lint/suspicious/useAwait: Intended
  private async sendBeacon(payload: string) {
    if (!this.endpoint) {
      return;
    }

    const success = window.navigator.sendBeacon(
      this.endpoint,
      new Blob([payload], {
        type: 'application/json',
      }),
    );

    if (!success) {
      throw new Error('Failed to send batch with Beacon');
    }
  }

  private async fetchWithRetry(payload: string, attempt = 1): Promise<void> {
    if (!this.endpoint) {
      return;
    }

    try {
      const response = await fetch(this.endpoint, {
        method: 'POST',
        body: payload,
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        // Sends the request even after navigating away from the page
        keepalive: true,
      });

      if (!response.ok) {
        const error = new Error(`Failed sending batch: ${response.status}`);

        if (!retryableStatusCodes.includes(response.status)) {
          error.name = 'NoRetryError';
        }

        throw error;
      }
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (error: any) {
      if (error.name === 'NoRetryError') {
        throw error;
      }

      if (attempt < this.retryAttempts) {
        //Exponential backoff
        await new Promise((resolve) =>
          setTimeout(resolve, this.retryDelay * 2 ** (attempt - 1)),
        );

        return this.fetchWithRetry(payload, attempt + 1);
      }

      throw error;
    }
  }

  private async sendBatches({ useBeacon = false } = {}) {
    if (this.queue.length === 0 || !hasTrackingConsent()) {
      return;
    }

    // Stop sending anything if we have failed 3 sendBatches calls continuosly
    // without any success, meaning:
    // 3(failedSendAttempts) * 3(retries) + 3(initial calls) = 12 times in total
    if (this.failedSendAttempts > 3) {
      this.stopFlushTimer();
      return;
    }

    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const chunks: Record<string, any>[][] = [];

    while (this.queue.length > 0) {
      chunks.push(this.queue.splice(0, this.maxBatchSize));
    }

    const shouldUseBeacon = useBeacon || document.visibilityState === 'hidden';

    const results = await Promise.allSettled(
      chunks.map((chunk) => {
        const data = JSON.stringify({ events: chunk });
        return shouldUseBeacon
          ? this.sendBeacon(data)
          : this.fetchWithRetry(data);
      }),
    );

    results.forEach((res, index) => {
      if (res.status === 'rejected') {
        this.failedSendAttempts++;
        this.queue.unshift(...(chunks[index] ? chunks[index] : []));
      }
    });

    if (this.queue.length === 0) {
      this.failedSendAttempts = 0;
      this.stopFlushTimer();
    }
  }

  private flush() {
    this.sendBatches();
  }

  /**
   * Clears the queue and stops the flush timer.
   */
  reset() {
    this.queue = [];
    this.stopFlushTimer();
  }
}

// Singleton so we batch properly across apps
// even with multiple instances of Tracker
export function getEventBatchTransporter() {
  if (typeof window === 'undefined') {
    return;
  }

  if (!window.VolvoCarsAnalytics?.eventBatchTransporter) {
    const eventBatchTransporter = new EventBatchTransporter();
    attachEventBatchTransporter(eventBatchTransporter);

    return eventBatchTransporter;
  }

  return window.VolvoCarsAnalytics.eventBatchTransporter;
}

function attachEventBatchTransporter(
  eventBatchTransporter: EventBatchTransporter,
) {
  if (!('VolvoCarsAnalytics' in window)) {
    Object.defineProperty(window, 'VolvoCarsAnalytics', {
      value: {},
      enumerable: true,
      writable: false,
      configurable: false,
    });
  }

  Object.defineProperty(window.VolvoCarsAnalytics, 'eventBatchTransporter', {
    value: eventBatchTransporter,
    enumerable: true,
    writable: false,
    configurable: true,
  });
}
